/* Hide Number Input Arrows */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
/* Hide Number Input Arrows */

.qntycol {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.qntycolqnty{
  width: 30px;
  text-align: center;
}


 .disccol {
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.disccoldisc{
  width: 50px;
  text-align: center;
} 


.selectednavitem{
  border: 1px solid black;
}

.MuiToolbar-root{
  min-height: 48px !important; 
}
.MuiAppbar-root{
backdrop-filter: blur(2px);
background: rgba(255,255,255,0.7);
border-style: solid;
  border-color: #E7EBF0;
  border-width: 0;
  border-bottom-width: thin;
  color : black;
}

.non_activ_link .indiv{
  border-radius: 0.5rem;
  filter: drop-shadow(0 10px 8px rgb(0 0 0 / 0.04)) drop-shadow(0 4px 3px rgb(0 0 0 / 0.1));
  background-color:#00000011;
  height:75px;
  width:75px;
}
.activ_link .indiv{
  border-radius: 0.5rem;
  filter: drop-shadow(0 10px 8px rgb(0 0 0 / 0.04)) drop-shadow(0 4px 3px rgb(0 0 0 / 0.1));
  border:1px solid indigo;
  color: indigo;
  background-color:#00000011;
  height:75px;
  width:75px;
}


.react-datepicker-popper{
  z-index:10;
}

th.MuiTableCell-root{
  padding: 6px;
}

td.MuiTableCell-root{
  padding: 0px;
}




.MuiRadio-root.MuiRadio-colorPrimary.MuiButtonBase-root.MuiRadio-root.MuiRadio-colorPrimary.PrivateSwitchBase-root{
  content-visibility:hidden;
}

/* .MuiRadio-root.MuiRadio-colorPrimary.MuiButtonBase-root.MuiRadio-root.MuiRadio-colorPrimary.PrivateSwitchBase-root.Mui-checked .MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label {
  color:red
} */


/* span.MuiTypography-root{
  border: 1px solid black;
  color:red
} */

.MuiRadio-root.Mui-checked .MuiTypography-root{
  border: 1px solid red;

  background-color:black;
  color:black;
}


.MuiTableContainer-root {
  text-transform:capitalize;
}


body {
  overflow: overlay;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 100px;
  background: rgba(149, 165, 166);
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.2);
}

div.MuiDialogActions-root{
  display: none;
}