@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
    font-family: "genos";
    font-style: normal;
    font-weight: normal; /* normal | 300 | 400 | 600 | bold | etc */
    src: url("assets/fonts/Genos-Regular.ttf") format("truetype");
  }

  @font-face {
    font-family: "oxygen";
    font-style: normal;
    font-weight: normal; /* normal | 300 | 400 | 600 | bold | etc */
    src: url("assets/fonts/Oxygen-Regular.ttf") format("truetype");
  }

  @font-face {
    font-family: "raleway";
    font-style: normal;
    font-weight: normal; /* normal | 300 | 400 | 600 | bold | etc */
    src: url("assets/fonts/Raleway-Regular.ttf") format("truetype");
  }

